<template>
	<div class="dF topbar relative jSB px-3" style="box-shadow: 0px 8px 28px rgba(100, 100, 100, 0.1); background: white;">
		<div class="dF">
			<img src="@/assets/logo.svg" style="max-height:40px;" />
		</div>
		<div class="dF jC text-center" style="gap:2ch;">
			<template v-if="false">
				<div @click="$emit('topbar', 'preview')" class="topButtons" style="width: 62px; height: 62px;">
					<a-icon style="font-size: 22px; line-height: 67px;" type="eye" />
				</div>
				<div @click="$emit('topbar', 'edit')" class="topButtons"
					style="width: 62px; height: 62px; line-height: 70px;">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none"
						stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
						class="feather feather-edit">
						<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
						<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
					</svg>
				</div>
				<div @click="$emit('topbar', 'code')" class="topButtons"
					style="width: 62px; height: 62px; line-height: 70px;">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 23.016 15.438">
						<g id="Icon_feather-code" data-name="Icon feather-code" transform="translate(-2 -7.586)">
							<path id="Path_7713" data-name="Path 7713" class="topIcon" d="M24,21.609l6.3-6.3L24,9"
								transform="translate(-6.289)" />
							<path id="Path_7714" data-name="Path 7714" class="topIcon" d="M9.3,9,3,15.3l6.3,6.3" />
						</g>
					</svg>
				</div>
			</template>
			<template v-else>
				<div @click="size.current = 'mobile'" class="topButtons" style="font-size:15px"
					:class="size.current === 'mobile' ? 'text-primary' : ''">
					<svg style="fill:currentColor" xmlns="http://www.w3.org/2000/svg" width="10.813" height="17.301"
						viewBox="0 0 10.813 17.301">
						<path
							d="M9.191,0H1.622A1.622,1.622,0,0,0,0,1.622V15.679A1.622,1.622,0,0,0,1.622,17.3H9.191a1.622,1.622,0,0,0,1.622-1.622V1.622A1.622,1.622,0,0,0,9.191,0ZM5.406,16.219a1.081,1.081,0,1,1,1.081-1.081A1.08,1.08,0,0,1,5.406,16.219ZM9.191,12.57a.407.407,0,0,1-.405.405H2.027a.407.407,0,0,1-.405-.405V2.027a.407.407,0,0,1,.405-.405H8.786a.407.407,0,0,1,.405.405Z" />
					</svg>
				</div>
				<div @click="size.current = 'desktop'" class="topButtons"
					:class="size.current === 'desktop' ? 'text-primary' : ''">
					<svg style="fill:currentColor" xmlns="http://www.w3.org/2000/svg" width="19.463" height="17.301"
						viewBox="0 0 19.463 17.301">
						<path
							d="M17.841,0H1.622A1.622,1.622,0,0,0,0,1.622V12.435a1.622,1.622,0,0,0,1.622,1.622H8.11l-.541,1.622H5.136a.811.811,0,0,0,0,1.622h9.191a.811.811,0,0,0,0-1.622H11.894l-.541-1.622h6.488a1.622,1.622,0,0,0,1.622-1.622V1.622A1.622,1.622,0,0,0,17.841,0ZM17.3,11.894H2.163V2.163H17.3Z" />
					</svg>
				</div>
			</template>
		</div>
		<div style="align-items:center;">
			<a-tooltip placement="bottom" overlayClassName="change-tooltip-color">
				<template slot="title">
					Export as HTML
				</template>
				<a-button @click="$emit('export')" class="mr-3" icon="export"></a-button>
			</a-tooltip>
			<a-tooltip placement="bottom" overlayClassName="change-tooltip-color">
				<template slot="title">
					Download PDF
				</template>
				<a-button @click="$emit('pdf')" class="mr-3" icon="download"></a-button>
			</a-tooltip>
			<a-button v-if="!$route.path.includes('template')" class="mr-3" style="background: transparent;"
				@click="saveAs">{{ $route.meta.edit ? 'UPDATE' : 'SAVE' }} {{ !$route.path.includes('scheduled') ? 'DRAFT' :  'SCHEDULED'}}</a-button>

			<a-popover v-model="sendTestBoolean" trigger="click" :title="null" placement="bottomRight"
				:overlayStyle="{ width: `400px` }">
				<div slot="content">
					<a-form>
						<a-form-item label="Enter emails">
							<div class="dF aC">
								<a-select @change="handleTestEmails" :token-separators="[',']" v-model="emails" mode="tags"
									placeholder="john@doe.com, jill@hive.com..." style="width:calc(100%-30px);">
									<a-select-option v-for="(email, emailI) in emailList" :value="email"
										:key="email + emailI">
										{{ email }}
									</a-select-option>
								</a-select>
								<div style="width:30px;">
									<a-button type="primary" icon="mail" @click="sendTest" />
								</div>
							</div>
						</a-form-item>
					</a-form>
				</div>
				<a-button class="mr-3" style="background: transparent;">SEND A TEST</a-button>
			</a-popover>
			<a-button v-if="$route.path.includes('template')" class="mr-3" @click="saveAs" type="primary">SAVE
				TEMPLATE</a-button>
			<a-button v-else class="mr-3" @click="doContinue" type="primary">CONTINUE</a-button>
			<a-button @click="$emit('close')" type="secondary" shape="circle" icon="close" style="border:0;" />
		</div>
	</div>
</template>

<script>
import { validateEmail } from 'bh-mod'
export default {
	props: ['size'],
	data: () => ({
		sendTestBoolean: false,
		saveAsBoolean: false,
		templateName: '',
		emails: [],
	}),
	watch: {
		sendTestBoolean(val) {
			if (!val) this.emails = []
		},
		saveAsBoolean(val) {
			if (!val) this.templateName = ''
		},
	},
	computed: {
		emailList() {
			return this.$store.state.email.allSettings.userApp.options.email || []
		},
		senders() {
            let senders = this.$store.state.email.senders;
            let sender = {};
            for (var i in senders) {
                if (senders[i].active) {
                    sender = JSON.parse(JSON.stringify(senders[i]));
                    break;
                }
            }
            return sender;
        },

        domain() {
            return this.$store.state.email.domain;
        },
	},
	methods: {
		doContinue() {
			if (this.$p < 40) return this.$message.error('You do not have permission to send broadcasts')
			if (!this.senders.active) {
                return this.$message.error(
                    "Please verify your email account in settings before proceeding."
                );
            }
            if (!this.domain.name) {
                return this.$message.error(
                    "Please add your domain details first in settings before proceeding."
                );
            }
            if (!this.domain.verified) {
                return this.$message.error(
                    "Please verify your domain first in settings before proceeding."
                );
            }
			if (!this.domain.hasSSLCertificate) {
                return this.$message.error(
                    "Please generate SSL certificate for your domain first in settings before proceeding."
                );
            }
			this.$emit('continue')
		},
		handleTestEmails() {
			this.emails = this.emails.filter(validateEmail)
		},
		saveAs() {
			if (this.$p < 10) {
				return this.$message.error(`You do not have permission to ${this.$route.meta.edit ? 'update' : 'save'} ${!this.$route.path.includes('scheduled') ? 'draft' :  'scheduled'}`)
			}
			this.$emit('save', this.templateName)
			this.saveAsBoolean = false
		},
		sendTest() {
			let error = false
			if (!this.emails.length) return
			let emails = this.emails

			emails.forEach(email => {
				if (error) return
				if (!validateEmail(email)) return error = true
			})

			if (error) return this.$message.error('One or more of your email is not a valid email')

			this.$emit('send', emails.join(','))

			let newEmails = []
			this.emails.forEach(x => {
				if (!this.emailList.includes(x)) {
					newEmails.push(x)
				}
			})
			if (newEmails.length != 0) {
				newEmails = this.emailList.concat(newEmails)
				this.$api.post(`/settings/:instance/email/user`, { options: { seenIntro: true, email: newEmails } }).then(({ data }) => {
					this.$store.commit('UPDATE_ALLSETTINGS', data)
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			}
			this.sendTestBoolean = false
		}
	}
}
</script>


<style lang="scss">
.topIcon {
	fill: none;
	stroke: #3f3356;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 2px;
}

.topButtons:hover {
	background-color: #FAF9F9;
	cursor: pointer;
	transition: background-color .1s ease-in-out;
}

.topbar {
	background: #FFF;
	width: 100%;
	height: 62px;
	border-bottom: 1px solid grey;
	border-color: transparent;
	padding: rem(15) rem(20);
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	box-shadow: rgba(100, 100, 100, 0.2) 0px 13px 32px;
}

.topButtons:hover {
	background-color: #FAF9F9;
	cursor: pointer;
	transition: background-color .1s ease-in-out;
}

.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
	background:#3F3356;
}
</style>
