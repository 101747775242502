<template >
    <div class=" bg-white px-3 section-card-component dF jSB aC" @mouseover="highlight">

        <!-- <span class="section-card-tag" v-if="item.tag" v-text="item.tag" /> -->

        <div @click="$emit('edit')" class="dF aC py-3 f1 cursor-pointer hover:text-primary relative" style="gap:1ch;">
            <template v-if="sectionCategory">
				<a-icon :type="categoryIcon" style="font-size: 20px;" />
			</template>
			<img v-else-if="image" :src="image" style="height:13px; width: 20px;" />
            <p class="mb-0 ">{{item.tag ? item.tag : item.name}}</p>

            <!-- <a-popover
            v-if="!item.tag"
            :title="null"
            :visible="renameBool"
            @visibleChange="() => {}"
            >
                <div slot="content">
                    <a-input type="text" />
                    <a @click="$emit('rename',false)">Close</a>
                </div>

                <i class="fe fe-edit-2" @click.stop="$emit('rename-close')" />

            </a-popover> -->



        </div>


        <a-tooltip overlayClassName="change-tooltip-color" title="Drag to rearrange" :mouseEnterDelay="2">
            <a-icon class="handle" v-if="!item.tag" type="menu" style="font-size:11px" />
        </a-tooltip>
    </div>
</template>

<script>
export default {
    props:['item','renameSection'],
    data:() => ({
        renameBool:false,
    }),
    watch:{
        renameSection(val){
            if (val !== this.renameBool) this.renameBool = val
        },
        renameBool(val){
            this.$emit('renameClose')
        },
    },
    computed:{
		image(){
            if (this.item.category == 'Header') return require('@/assets/icons/header-revised.svg')
            // if (this.item.category == 'Carousel') return require('@/assets/icons/carousel-revised.svg')
            // if (this.item.category == 'Image' || this.item.category == 'Logo') return require('@/assets/icons/image-revised.svg')
            if (this.item.category == 'Button') return require('@/assets/icons/buttons-revised.svg')
            if (this.item.category == 'Footer') return require('@/assets/icons/footer-revised.svg')
            // if (this.item.category == 'Columns') return require('@/assets/icons/columns-revised.svg')
            if (this.item.tag) return require(`@/assets/icons/${this.item.tag.toLowerCase()}.svg`)
            if (!this.item.icon) return require(`@/assets/icons/content-revised.svg`)
            if (this.item.icon.indexOf('http') === 0 && this.item.icon.includes('://')){
                return this.item.icon
            }
            return require(`@/assets/icons/content-revised.svg`)
        },
		sectionCategory(){
			let categories = ['Carousel', 'Columns', 'Contact Us', 'Content', 'Hero', 'Image', 'Logo', 'Text']
			return categories.includes(this.item.category)
		},
		categoryIcon(){
			let icons = {
				'Carousel': 'double-right',
				'Columns': 'project',
				'Contact Us': 'customer-service',
				'Content': 'pic-left',
				'Hero': 'star',
				'Image': 'picture',
				'Logo': 'smile',
				'Text': 'align-left',
			}
			return icons[this.item.category]
		}
    },
    methods:{

        highlight(){
            if (document.querySelector('iframe') && this.item.id){
                document.querySelector('iframe').contentWindow.postMessage({action:'highlightSection',id:this.item.id})
            }

        }
    }
}
</script>

<style lang="scss">
    .section-card-component{
        border:1px solid var(--light-purple);
        border-left:0;
        border-right:0;
        position: relative;
        + .section-card-component{
            border-top:0;
        }
    }
    .handle{
        cursor:-moz-grab;
        cursor:-webkit-grab;
    }
    .section-card-tag{
        position: absolute;
        top:3px;
        left:20px;
        font-size:12px;
        margin-left:calc(20px + 1ch);
        color:var(--med-gray);
    }
</style>
