<template>
  <div v-if="obj.show" class="f1 relative">
            <a-form class="section-editor-container bg-white py-4" style="position:absolute; top:0;left:0;right:0;bottom:0;overflow:hidden;">
                <div style="position: absolute; padding-bottom: 100px; inset: 0px; overflow: hidden scroll;">
                <template v-for="(item,itemI) in obj.item.settings">
                    <div v-if="item.type === 'header'" :key="`section-${obj.item.id}-item-${itemI}`" class="section-editor-grouped" :class="item.children && item.children.length ? 'section-editor-accordion-wrapper' : ''">
                        <h6 v-if="!item.children" v-text="item.label"/>
                        <a-collapse :defaultActiveKey="obj.item.settings.length === 1 ? '1' : '5'" v-else-if="item.children.length" expandIconPosition="right" style="border:0;">
                            <a-collapse-panel key="1" :header="item.label" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;border:0;'">
                                <template v-for="(childItem,childItemI) in item.children">
                                    <template v-if="isShown[childItem.id]">
                                        <a-form-item :label="childItem.label" :key="`section-${obj.item.id}-item-${childItemI}${childItem.id}`" :help="showHelp(childItem,obj.item.data[childItem.id])">
                                            <SectionEditItem :font-list="fontList" :key="`section-${obj.item.id}-item-${childItemI}${childItem.id}`" :data="obj.item.data" :setting="childItem"/>
                                        </a-form-item>
                                    </template>
                                </template>
                            </a-collapse-panel>
                        </a-collapse>

                    </div>
                    <template v-else-if="isShown[item.id]">
                        <a-form-item :label="item.label" :key="`section-${obj.item.id}-item-${itemI}`"  :help="showHelp(item)" class="px-3"  >
                            <SectionEditItem :font-list="fontList"  :data="obj.item.data" :setting="item"/>
                        </a-form-item>
                    </template>

                </template>
                <BlocksEditor  :font-list="fontList"  v-if="obj.item.blocks && obj.item.blocks.blocks && obj.item.blocks.blocks.length" :item="obj.item" />


                <slot />
                </div>
            </a-form>

  </div>
</template>

<script>
import {setProp} from 'bh-mod'
import draggable from 'vuedraggable'
import SectionEditItem from '@/components/email/SectionEditItem'
import BlocksEditor from '@/components/email/BlocksEditor'

export default {
    props:['obj','fontList'],
    data:( () => ({
        activeKey:'1',
        timeOut:null
    })),
    watch:{
        obj:{
            deep:true,
            handler(val){
				console.log('VAL OF OBJ', val)
                this.activeKey = '0'
                clearTimeout(this.timeOut)
                this.timeOut = setTimeout(() => {
                    this.activeKey = '1'
                }, 150);
            }
        }
    },
    components:{
        draggable,
        SectionEditItem,
        BlocksEditor

    },
    computed:{
        values(){
            let values = {}
            this.obj.item.settings.forEach( item => {
                values[item.id] = this.obj.item.data[item.id]

                if (item.children && item.children.length){
                    item.children.forEach(childItem => {
                        values[childItem.id] = this.obj.item.data[childItem.id]
                    })
                }
            })
            return values
        },
        isShown(){


            let isShown = {}


            this.obj.item.settings.forEach( item => {
                if (!item.id) return
                if (item.type === 'header' && !item.children) return
                else if (item.children && item.children.length){
                    item.children.forEach(childItem => {
                        let is = true
                        if (childItem.hasOwnProperty('onlyIf')){
                            let checkFor = true
                            let keyToCheck = childItem.onlyIf
                            if (keyToCheck.indexOf('!') === 0){
                                checkFor = false
                                keyToCheck = keyToCheck.substr(1)
                            }
                            is = Boolean(this.values[childItem.onlyIf]) === checkFor
                        }
                        isShown[childItem.id] = is
                    })
                }
                else isShown[item.id] = !item.onlyIf ?  true : Boolean(this.values[item.onlyIf])
            })
            return isShown
        }
    },
    methods:{
        showHelp(item,val){
            if (!val) return item.help || ''
            if (item.type === 'linkText') {
                if (val.indexOf('http') !== 0){
                    return 'Note: your link does not seem valid'
                } else if (!item.help){
                    return 'Enter a valid Link'
                }
            }
            if (item.help) return item.help
            return ''
        },
    },

}
</script>

<style>

</style>
