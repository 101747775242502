<template>
    <div>
        <template v-if="setting.type === 'text'">
            <RichText v-model="data[setting.id]" :format="false" builder="email" />
        </template>
        <template v-else-if="setting.type === 'linkText'">
            <a-input v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'linkTextArea'">
            <a-textarea v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'range'">
            <a-slider :vertical="Boolean(setting.vertical)" :style="`height:${setting.vertical? '100px' : 'auto'}`" :tipFormatter="e => setting.suffix ?  e + setting.suffix : e" v-model="data[setting.id]" :min="isNaN(setting.min) ? 1 : setting.min" :max="setting.max > 0 ? setting.max : 100" />
        </template>
        <template v-else-if="setting.type === 'options'">
            <a-select v-model="data[setting.id]" :options="setting.options" />
        </template>
        <template v-else-if="setting.type === 'font'">
            <a-select v-model="data[setting.id]" :options="fontList" />
        </template>
        <template v-else-if="setting.type === 'switch' || setting.type === 'checkbox' || setting.type === 'boolean'">
            <a-switch v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'image'">
            <ImageBoxSelector v-model="data[setting.id]" :removable="true" />
        </template>
        <template v-else-if="setting.type === 'instance'">
            <InstanceSelector v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'image-with-alt'">
            <ImageBoxAlt v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'modelConfig'">
            <ModelConfigurator v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'images'">
            <ImageBoxSelectorGroup v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'richtext'">
            <RichText v-model="data[setting.id]" :format="true" builder="email" />
        </template>
        <template v-else-if="setting.type === 'color'">
            <ColorPicker v-model="data[setting.id]" />
        </template>
        <template v-else-if="setting.type === 'button'">
            <ButtonEditor v-model="data[setting.id]" />
        </template>
		<template v-else-if="setting.type === 'custom'">
			<a-textarea v-model="data[setting.id]" :rows="8"></a-textarea>
		</template>
    </div>
</template>

<script>
import {setProp} from 'bh-mod'

export default {
    name:'SectionEditor',
    props:['data','setting','font-list'],
    components:{
        ImageBoxSelector: () => import('bh-mod/components/common/ImageBoxSelector'),
        ImageBoxAlt: () => import (`bh-mod/components/common/ImageBoxAlt`),
        ImageBoxSelectorGroup: () => import('bh-mod/components/common/ImageBoxSelectorGroup'),
        RichText: () => import('bh-mod/components/common/RichText'),
        RichText: () => import('@/components/common/RichText'),
        ColorPicker: () => import('@/components/email/ColorPicker'),
        ButtonEditor: () => import('@/components/email/ButtonEditor'),
        ModelConfigurator: () => import('@/components/email/ModelConfigurator'),
    },
}
</script>

<style>

</style>