<template >
    <div class="" >

        <a-form class="section-editor-container bg-white py-4 px-3">
            <a-form-item label="Primary Font">
                <a-select v-model="primary" :options="list" />
            </a-form-item>
            <a-form-item label="Secondary Font">
                <a-select v-model="secondary" :options="list" />
            </a-form-item>
        </a-form>

    </div>
</template>

<script>
export default {
    props:['email'],
    data:() => ({
        
    }),
    computed:{
        fonts(){
            return this.$store.getters.fonts
        },
        list(){
            return this.fonts.map(x => ({
                label:x.name,
                value:x.name
            }))
        },
        primary:{
            set(val){
                let font = this.fonts.find(x => x.name === val)
                if (!font) return 

                this.email.fonts.primary = JSON.parse(JSON.stringify(font))
            },
            get(){
                return this.email.fonts.primary.name
            }
        },
        secondary:{
            set(val){
                let font = this.fonts.find(x => x.name === val)
                if (!font) return 

                this.email.fonts.secondary = JSON.parse(JSON.stringify(font))
            },
            get(){
                return this.email.fonts.secondary.name
            }
        }
    },
    methods:{
        
       
    }
}
</script>

<style lang="scss">
   .bt-next{
       border-top:1px solid var(--light-purple);
       border-bottom:1px solid var(--light-purple);
       & + .bt-next{
           border-top:0;
       }

   }
</style>