<template>
    <div class="manage-section h-full dF fC">
        <div class="dF aC px-3 py-4 text-white bg-primary" style="gap:1ch;" v-if="editSectionObj.show && editSectionObj.item" >
            <template >
                <a-icon type="arrow-left" class="cursor-pointer" @click="$store.commit('SET_PROP', {where:['editSectionObj','show'],what:false})" />
                <h6 class="mb-0 text-white" >{{editSectionObj.item.name}}</h6>
            </template>
        </div>
        <div class="dF aC px-3 py-4" style="gap:1ch;" v-else >
            <h6 class="mb-0" style="font-size: 1.15em;">Sections</h6>
        </div>
        <EditSection :fontList="fontList" v-if="editSectionObj.show" :obj="editSectionObj">
            <div class="mt-4 dF px-3" style="gap:1ch; flex-wrap:wrap;">

                <a  href="#" @click.prevent="replaceSection()" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc">
                    <i class="fe fe-refresh-ccw" /> Replace
                </a>
                <a-popover trigger="click"  placement="left" :auto-adjust-overflow="true" @visibleChange="saveSectionName = ''" :overlayStyle="{width:'350px'}">
                    <a-form slot="content">
                        <div>
                            <p class="mb-1 text-primary" style="font-size:16px;">Save this section with current options. to reuse in other email designs. </p>
                        </div>
                        <a-form-item label="Name your Section">
                            <a-input-search placeholder="Your custom Section" @search="saveSection" v-model="saveSectionName">
                                <a-button slot="enterButton" type="primary" icon="check" />
                            </a-input-search>
                        </a-form-item>
                    </a-form>
                    <a href="#" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc;">
                        <a-icon type="save" />
                    </a>
                </a-popover>
                <a v-if="!editSectionObj.item.tag" href="#" @click.prevent="duplicateSection(editSectionObj.item)" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc;">
                    <a-icon type="copy" /> Duplicate
                </a>
                <a v-if="!editSectionObj.item.tag" href="#" @click.prevent="removeSection(editSectionObj.item.id)" class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc; border-color:var(--danger); color:var(--danger)">
                    <a-icon type="minus-circle" /> Remove
                </a>


            </div>
        </EditSection>


        <template v-else>
            <div class="mb-3">
                <SectionCard v-for="item in getHeaders" :key="item.id" :item="item" @edit="editSection(item)"  />
            </div>

            <!-- SECTIONS -->
            <div class="list-group mb-3">

                <draggable :list="sections" handle=".handle" :delay-on-touch-only="true" :delay="100">
                    <SectionCard @rename-close="renameSection = false" @rename="renameSection = item.id" :rename-section="renameSection===item.id" @edit="editSection(item)" :item="item"  v-for="item in sections" :key="item.id" />
                </draggable>

                <div @click="addSection" class="px-4 section-card-component dF aC jC text-primary cursor-pointer section-add-card">
                    <a-icon type="plus-circle" />
                    <p class="mb-0 py-3 cursor-pointer text-primary hover:text-primary">Add section</p>
                </div>

            </div>

            <div class="footer">
                <SectionCard v-for="item in getFooters" :key="item.id" :item="item" @edit="editSection(item)"  />
            </div>
        </template>
    </div>
</template>

<script>
import {setProp} from 'bh-mod'
import SectionCard from '@/components/email/SectionCard'
import SectionModal from '@/components/email/SectionModal'
import EditSection from '@/components/email/EditSection'
import draggable from 'vuedraggable'

function modify(obj, newObj) {

  Object.keys(obj).forEach(function(key) {
    delete obj[key];
  });

  Object.keys(newObj).forEach(function(key) {
    obj[key] = newObj[key];
  });

}
export default {
    components:{
        draggable,SectionCard,SectionModal,EditSection,
    },
    props:['email','event'],
    inject:['updateSelectedSection'],
    data:()=>({
        msg:'hey',
        // editSectionObj:{
        //     show:false,
        //     item:{}
        // },
        saveSectionName:'',
        renameSection:'',

    }),
    watch:{
        event(val){
            if (typeof val === 'object'){
                if (val.action === 'sectionEdit'){
                    console.log('SECTION EDIT EVENT', val)
                    let id = val.id.replace('section-id-','')
                    let item = this.page.sections.find(x => x.id === id)
                    if (val.classList.includes('section-header') || val.classList.includes('section-footer')){
                        if (this.page.header.id === id)item = this.page.header
                        else item = this.page.footer
                        item.tag = this.page.header.id === item.id ? 'header' : 'footer'
                    }
                    console.log('ABOUT TO EDIT', item)
                    if (item){
                        this.editSection(item)
                    }

                }
            }
        }
    },
    computed:{
        editSectionObj(){
            return this.$store.state.email.editSectionObj
        },
        getHeaders(){
            console.log('GETTING HEADERS', this.page)
            if (this.page.header && this.page.header.template) return [{...this.page.header,tag:'Header'}]
            if (this.email.headers && this.email.headers.length) return this.email.headers.map(x => ({...x,tag:'Header'}))
            return false
        },
        getFooters(){
            if (this.page.footer && this.page.footer.template) return [{...this.page.footer,tag:'Footer'}]
            if (this.email.footers && this.email.footers.length) return this.email.footers.map(x => ({...x,tag:'Footer'}))
            return false
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        siteURL(){
            return this.$store.state.email.siteURL
        },
        fontList(){
            return Object.values(this.email.fonts)
                .map( ({name,fontText}) => ({
                    label:name,
                    value:fontText
                }))
        },
        page(){
            return this.email.page || {}
        },
        sections(){
            return this.page.sections || []
        }
    },
    methods:{
        async saveSection(){
            let section = JSON.parse(JSON.stringify(this.editSectionObj.item))
            console.log('WANT TO SAVE THIS', section, 'width this name => :', this.saveSectionName)
			section.settings.forEach(x => {
				if (x.children){
					x.children.forEach(y => {
						if (section.data.hasOwnProperty(y.id)){
							y.default = section.data[y.id]
						}
					})
				}
			})
            delete section.id
            delete section._id
            section.instance = this.$store.state.instance.id
            section.name = this.saveSectionName
			section.default = true
			section.custom = true
            let {data} = await this.$api.post(`/sections/:instance`, section).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
            this.$store.commit('SET_PROP', {where:['sections',data.id],what:data})
            this.saveSectionName = ''
            this.$message.success('Section saved!')
        },
        addSectionCallBack(item, dup){
            item = JSON.parse(JSON.stringify(item))
			console.log('ITEM', item)
            let index = -1
            if (dup){
                index = this.email.page.sections.findIndex(x => x.id === item.id)
            }
            item.id = 'add_' + Date.now()

			console.log('ITEM.bocks', item.blocks)
			if (item.blocks && !item.blocks.blocksData) item.blocks.blocksData = []
            if (item.settings && item.settings.length){
                let settings = JSON.parse(JSON.stringify(item.settings))
                settings.forEach( (setting) => {
					if (!dup){
						if (setting.hasOwnProperty('default')){
							item.data[setting.id] = setting.default
						} else if (setting.children && setting.children.length){
							setting.children.forEach(childS => {
								if (childS.hasOwnProperty('default')){
									item.data[childS.id] = childS.default
								}
							})
						}
					}
                })
            }
            if (dup && index > -1){
                this.email.page.sections.splice(index,0,item)
            } else {
                this.email.page.sections.push(item)
            }
            this.editSection(item)
        },
        replaceSectionCallback(item){
            item.id = 'add_' + this.editSectionObj.item.id
			if (!item.data) item.data = {}

            console.log('ITEM.bocks', item.blocks)
            if (item.blocks && !item.blocks.blocksData) item.blocks.blocksData = []
            if (item.settings && item.settings.length){
                let settings = JSON.parse(JSON.stringify(item.settings))
                settings.forEach(setting => {

                    if (setting.hasOwnProperty('default')){
                        item.data[setting.id] = setting.default
                    }else if (setting.children && setting.children.length){
                        setting.children.forEach(childS => {
                            if (childS.hasOwnProperty('default')){
                                item.data[childS.id] = childS.default
                            }
                        })
                    }
                })
            }
			delete item._id
            let tag = 'NONENONE'
            if (this.editSectionObj.item.tag) tag = this.editSectionObj.item.tag.toLowerCase()
            if (this.page[tag]){
                item.tag = this.editSectionObj.item.tag
                setProp(this.page,[tag],item)
                setProp(this.editSectionObj,['item'],item)
            } else {
                let sections = this.sections.map(x => x.id === this.editSectionObj.item.id ? item : x )
                setProp(this.page,['sections'],sections)
                setProp(this.editSectionObj,['item'],item)
            }
			console.log('ITEM FROM REPLACE SECTION', item)

            // this.editSection(item)
        },
        replaceSection(id){
            this.$store.commit('SET_PROP',{where:['sectionModal'],what:{init:true, show:true, cb:this.replaceSectionCallback, restriction:this.editSectionObj.item.restriction, replace:true }})
        },
        addSection(){
            this.$store.commit('SET_PROP',{where:['sectionModal'],what:{init:true, show:true, cb:this.addSectionCallBack, restriction:'none' }})
        },

        editSection(item){
            console.log('EDIT SECTION', this.updateSelectedSection, item)
            this.$store.commit('SET_PROP', {where:['editSectionObj'], what:{
                show:true,
                item
            }})
        },
        duplicateSection(item){
			console.log('DUPLICATING SECTION...', item)
            this.addSectionCallBack(item, true)
        },
        removeSection(id){
            this.$confirm({
                title: "Delete",
                content: h => <div>Do you want to delete this Section?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk:() => {
                    this.email.page.sections = this.email.page.sections.filter(x => x.id !== id)
                    this.$store.commit('SET_PROP', {where:['editSectionObj'],what:{show:false,item:{}}})
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
    }
}
</script>

<style lang="scss">
    .section-editor-accordion-wrapper{
        +.section-editor-accordion-wrapper{
            border-top:1px solid var(--light-purple);
        }
    }
    .section-add-card{
        gap: 1ch;
        border-top: 0px;
        margin: 5px 5px;
        background: rgb(255, 255, 255);
        border-radius: 4px;
        border: 1px dashed currentColor;
        transition:background-color .3s ease-out;
        &:hover{
            background:#efebe6;
        }
    }
</style>
