<template>


	<div class="email-builder builder-screen" v-if="dialog.show && dialog.data && Object.keys(dialog.data).length > 0">

	  <bh-loading :show="loading" />

	  <div style="width:100vw; height:100vh" class="dF fC ">

		<TopBarTemplate v-if="isTemplate" :size="frameSize" @close="closeTemplate" @save="saveTemplate" @send="sendTest" @topbar="topbarAction" @pdf="downloadPdf" @export="exportHtml" />
		<TopBarTemplate v-else :size="frameSize" @close="close" @save="save" @send="sendTest" @continue="saveAndContinue" @topbar="topbarAction" @pdf="downloadPdf" @export="exportHtml" />

		<div class="w-full dF f1 bg-white">
			<div class="f1 relative email-builder-wrapper">
			  <div class="iframe-holder" :style="iframeStyle" :class="frameSize.current === 'mobile' ? 'phone phone-iframe-container' : '' ">
				<iframe class="email-builder"  />
			  </div>
			</div>
		  <SideToolbar :event="windowMsg" :email="emailObj" :selected-section="selectedSection" />
		</div>

	  </div>

  </div>
</template>

<script>
  import {sections} from '@/assets/sections'
  import {formatNumber, validateEmail,setProp} from 'bh-mod'
  import SideToolbar from '@/components/common/SideToolbar'
  import bhLoading from 'bh-mod/components/common/Loading'
  import TopBarTemplate from '@/components/email/TopBarTemplate'
  import axios from 'axios'
  import jsPDF from 'jspdf'

  let scrollTop = 0
	function dataURItoBlob(dataURI) {
		// convert base64 to raw binary data held in a string
		// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
		var byteString = atob(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to an ArrayBuffer
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		//Old Code
		//write the ArrayBuffer to a blob, and you're done
		//var bb = new BlobBuilder();
		//bb.append(ab);
		//return bb.getBlob(mimeString);

		//New Code
		return new Blob([ab], {type: mimeString});


	}
  const modelStyles = {
	color1:null,
	hover1:null,
	color2:null,
	hover2:null,
	color3:null,
	hover3:null,
	column:null,
	imgEffect:null
  }

  export default {
	components:{
	  bhLoading,
	  TopBarTemplate,
	  SideToolbar,

	},
	provide() {
	  return {updateSelectedSection:this.updateSelectedSection}
	},
	data() {
	  return {
		sendTestBoolean:false,
		selectedSection:'',
		sections,
		emailObj:{},
		broadcastObj:{},
		windowMsg:{},
		buttonTool:{
			show:false,
			configure:{}
		},
		frameSize:{
			current:'desktop'
		},
		linkTool:{
			show:false,
			configure:{
				link:'',
				linkClass:''
			}
		},
		SRC:'',
		recentColorLookup2:{},
		recentColors:[],
		colorPicker:{
			show:false,
			callback:null,
			path:[]
		},
		addContent:{
			show:false,
		},
		widgetSetting:{
			show:false,
			type:'',
			configure:{}
		},
		widgetStyle:{
			show:false,
			type:'',
			configure:{
				bg:'#fff',
				titleColor:'#F00',
				titleHover:'#FF0',
				textColor:'#00F',
				textHover:'#F0F',
			}
		},
		navMenus:{},
		pages:[],
		IFRAME:null,
		previewing:false,
		previewFields:[],
		leadScoreShow:false,
		customFields:{},
		showAddCustomField:false,
		deleting:false,
		deletePopup:false,
		loading:false,
		template:{
			name:'',
			url:'',
			instance:'',
			published:false,
		},
		tags:[],
	  }
	},
	watch:{
		editSectionObj:{
			deep:true,
			handler(val){
				if (val.show){
					if (this.editSectionObj.item && this.editSectionObj.item.id){
						document.querySelector('iframe').contentWindow.postMessage({action:'selectedSection',id:this.editSectionObj.item.id})
					}
				} else {
					document.querySelector('iframe').contentWindow.postMessage({action:'selectedSection',id:''})
				}
			},
		},
		'frameSize.current'(val){
			console.log('VALLLL CHANGED SIZE', val)
			var iframe = document.querySelector("iframe");
			var html = iframe.contentWindow.document.querySelector("html");
			var body = iframe.contentWindow.document.querySelector("body");
			if (val == 'mobile'){
				html.style.zoom = '80%'
				html.style.overflow = 'hidden'
				html.style.height = '100%'
				body.style.width = '100%'
				body.style.height = '100%'
			} else {
				html.style.zoom = '100%'
				html.style.overflow = 'hidden'
				body.style.width = 'calc(100% + 16px)'
				body.style.height = '100vh'
			}
		},
		'emailObj.fonts':{
			deep:true,
			handler(val){
				console.log('FONT CHANGING')
				const  makeAPICall = () => {
					this.updateFrame()
				}

				if (this.timerId)  clearTimeout(this.timerId)

				this.timerId  =  setTimeout(function () {
					makeAPICall()
					this.timerId  =  undefined;
				}, 400)
			}
		},
		'emailObj.data':{
			deep:true,
			handler(val){
				console.log('FONT CHANGING')
				const  makeAPICall = () => {
					this.updateFrame()
				}

				if (this.timerId)  clearTimeout(this.timerId)

				this.timerId  =  setTimeout(function () {
					makeAPICall()
					this.timerId  =  undefined;
				}, 400)
			}
		},
		'emailObj.page':{
			deep:true,
			handler(val){
				this.doUpdate()
			}
		},
		activeKey(val){
			console.log('CHANGING PACKAGE', val)
			this.openNewFloor = val.key + 0
		},
		dialog:{
			async handler(val){

				if (val.type === 'edit' && val.data && val.data.name){

					let OBJ = JSON.parse(JSON.stringify(val.data))
					if (!OBJ.data) OBJ.data = {}
					console.log('OBJ FROM DIALOG', OBJ)
					this.emailObj = OBJ
					this.$store.commit('LOAD',false)
					setTimeout(() => {
						if (val){
							console.log('UPDATING FRAMe from dialog watcher')
							this.updateFrame()
						}
					}, 1000);

				}
			},
			deep:true
		},
		tempEmail(val){
			if (val.page){
				console.log('TEMP EMAIL  UPDATEd')
			}
		},
		fields(val){
			if (val.length){
				this.refreshFields(val)
			}
		}
	},
	computed:{
		senders(){
			let senders = this.$store.state.email.senders
			let sender = {}
			for (var i in senders){
				// console.log('iiii', i)
				sender = JSON.parse(JSON.stringify(senders[i]))
			}
			return sender
		},
		isTemplate(){
			return this.$route.path.includes('template')
		},
		editSectionObj(){
			return this.$store.state.email.editSectionObj
		},
		tempEmail(){
			return this.$store.state.email.tempEmail
		},
		iframeStyle(){
			return {
			// width:this.frameSize.current === 'mobile'? '456px' : '100%',
			// height:this.frameSize.current === 'mobile'? '700px' : '100%'
			}
		},
		cson(){
			return this.$store.state.email.cson
		},
		siteURL(){
			return this.$store.state.email.siteURL
		},
		viewPortWidth(){
			return this.$store.state.email.dom.w
		},
		recentColorLookup(){

			let type = this.widgetStyle.type
			if (!type || !this.widgetStyle.show) return []

			let lookup = {
				bg1:['all'],
				color1:['models','navigation','md-fp-details-1'],
				hover1:['models','navigation','md-fp-details-1'],
				bg2:['md-fp-details-1'],
				color2:['models','navigation','md-fp-details-1'],
				hover2:['models'],
				color3:['models','navigation'],
				hover3:['models'],
			}

			let colorsEntries = Object.entries(lookup).filter( ([key,value]) => {
				return value.includes('all') || value.includes(type)
			}).map(x => x[0])

			let result = []

			colorsEntries.forEach(clr => {
				let color = this.widgetStyle.configure[clr]
				if (!result.includes(color)) result.push(color)
			})

			console.log('RESULTTTTTTTTTTTTTT', result)
			return result

		},
		modelTags(){
			return this.tags.filter(x => x.type === 'models')
		},
		lotTags(){
			return this.tags.filter(x => x.type === 'lots')
		},
		instance(){
			return this.$store.state.instance
		},
		dialog(){
			return this.$store.state.email.templateDrawer
		},
	},
	methods: {
		handleUpload(fileObj){
            return new Promise( (resolve,reject) => {
                const formData = new FormData();
                formData.append('files', fileObj, `${new Date().getTime()}.jpg`);

                this.$api.post('/upload',formData,{headers: { 'Content-Type': 'multipart/form-data' }}).then( ({data}) => {
                    if (Array.isArray(data) && data[0]){
                        resolve(data[0].url)
                    } else {
                        reject()
                    }
                }).catch( err => {
                    this.$message.error(this.$err(err))
                    reject()
                })
            })

        },
		checkUnsubscribeLink(){
			var iframe = document.querySelector("iframe");
			var body = iframe.contentWindow.document.querySelector("body");

			if (body && body.innerHTML.includes('"%recipient.unsub%"')) {
				return true;
			} else {
				return false;
			}
		},
	doUpdate(){
		const  makeAPICall = () => {
			this.updateFrame()
		}

		if (this.timerId)  clearTimeout(this.timerId)

		this.timerId  =  setTimeout(function () {
			makeAPICall()

			this.timerId  =  undefined;
		}, 400)
	},
	updateSelectedSection(e){
		console.log('RAN UPDATE', e)
	},
	packageProject(){


		  let project = JSON.parse(JSON.stringify(this.emailObj))
		  project.pages = [project.page]

		  delete project.page
		  let pages = project.pages
		  project.default = false
		  delete project.updated_by
		  delete project.created_by

		  if (project.headers && project.headers.length){

			  project.headers.forEach(header => {
				header.default = false
				delete header.updated_by
				delete header.created_by
				if (header.id && header.id.includes('add_')){
				  delete header.id
				  delete header._id
				}
			  })

		  }


		  if (project.footers && project.footers.length){

			  project.footers.forEach(footer => {
				delete footer.updated_by
				delete footer.created_by
				footer.default = false
				if (footer.id && footer.id.includes('add_')){
				  delete footer.id
				  delete footer._id
				}
			  })

		  }

		  pages.forEach(page => {

			  if (page.header){
				page.header.default = false
				delete page.header.updated_by
				delete page.header.created_by
				if (page.header.id && page.header.id.includes('add_')) {
				  delete page.header.id
				  delete page.header._id
				}
			  }

			  if (page.footer){
				delete page.footer.updated_by
				delete page.footer.created_by
				page.footer.default = false
				if (page.footer.id && page.footer.id.includes('add_')) {
				  delete page.footer.id
				  delete page.footer._id
				}
			  }


			  page.sections = page.sections.map( sec => {
				sec.default = false
				sec.custom = false
				let {id,_id,updated_by,created_by,...newSec} = sec
				if (sec.id && sec.id.includes('add_')) return newSec
				return sec
			  })

		  })

		  return project

	},
	packageTemplateProject(){

		  let project = JSON.parse(JSON.stringify(this.emailObj))
		  project.pages = [project.page]

		  delete project.page
		  let pages = project.pages
		  project.default = true
		  project.instance = this.instance.id
		  if (project.broadcast){
			project.name = project.broadcast.name
		  } else {
			project.name = project.name
		  }
		  delete project.updated_by
		  delete project.created_by

		  if (project.headers && project.headers.length){

			  project.headers.forEach(header => {
				header.default = false
				delete header.updated_by
				delete header.created_by
				if (header.id && header.id.includes('add_')){
				  delete header.id
				  delete header._id
				}
			  })

		  }


		  if (project.footers && project.footers.length){

			  project.footers.forEach(footer => {
				delete footer.updated_by
				delete footer.created_by
				footer.default = false
				if (footer.id && footer.id.includes('add_')){
				  delete footer.id
				  delete footer._id
				}
			  })

		  }

		  pages.forEach(page => {

			  if (page.header){
				page.header.default = false
				delete page.header.updated_by
				delete page.header.created_by
				if (page.header.id && page.header.id.includes('add_')) {
				  delete page.header.id
				  delete page.header._id
				}
			  }

			  if (page.footer){
				delete page.footer.updated_by
				delete page.footer.created_by
				page.footer.default = false
				if (page.footer.id && page.footer.id.includes('add_')) {
				  delete page.footer.id
				  delete page.footer._id
				}
			  }


			  page.sections = page.sections.map( sec => {
				sec.custom = false
				sec.default = false
				let {id,_id,updated_by,created_by,...newSec} = sec
				if (sec.id && sec.id.includes('add_')) return newSec
				return sec
			  })

		  })

		  return project

	},
	async continueSavingTemplate(img){
		let imgUrl = ''
		try {
			let file = dataURItoBlob(img)
			imgUrl = await this.handleUpload(file)
		} catch(err){
			console.error('ERROR UPLOADING', err)
		}

		if (imgUrl){
			this.pkgProject.image = imgUrl
		}

		if (this.pkgProject.id){

			this.$api.put(`/projects/:instance/${this.pkgProject.id}`,this.pkgProject).then( ({data}) => {
				this.$message.success('Succesfully Saved')
				this.$store.commit('SET_PROP',{where:['templates',data.id],what:data})
				if (this.saveCb && typeof this.saveCb === 'function') this.saveCb()
			}).catch( (err) => {
				console.log('ERRRRRR', err)
				this.$message.error('There was an error saving your file')
			})
			.finally( () => {
				this.$store.commit('LOAD',false)
			})

		} else {
			this.$api.post(`/projects/:instance/`,this.pkgProject).then( async ({data}) => {
				if (data.id){
					this.$store.commit('SET_PROP',{where:['templates',data.id],what:data})
					this.$message.success('Succesfully Saved')
					this.$router.replace(`/template/${data.id}?edit`)
				}
			})
			.catch(err => {
				console.log('ERRRRRR', err)
				this.$message.error('A problem happened. Please try and save again.')
			})
			.finally( () => {
				this.$store.commit('LOAD',false)
			})


		}

	},
	async continueSaving(img){
		let imgUrl = ''

		if (img && img !== ''){

			try {
				let file = dataURItoBlob(img)
				imgUrl = await this.handleUpload(file)
			} catch(err){
				console.error('ERROR UPLOADING', err)
			}
		}

		if (imgUrl){
			this.pkgProject.image = imgUrl
		}

		if (this.pkgProject.id){

			this.$api.put(`/projects/:instance/${this.pkgProject.id}`,this.pkgProject).then( async ({data}) => {
				// let {subject,preheader,name} = this.broadcast
				// let {data:br} = await this.$api.put(`/broadcasts/:instance/${this.broadcast.id}`, {subject,preheader,name})
				// this.$store.commit('SET_PROP', {where:['broadcasts',br.id],what:br})
				this.$message.success('Succesfully Saved')
				if (this.saveCb && typeof this.saveCb === 'function') this.saveCb()
				this.saveCb = null
			}).catch( (err) => {
				console.error(err)
				this.$message.error('There was an error saving your file')
			})
			.finally( () => {
				this.$store.commit('LOAD',false)
			})

		} else {
			this.$api.post(`/projects/:instance/`,this.pkgProject).then( async ({data}) => {
				if (data.id){
					this.emailObj.broadcast.project = data.id
					let {data:br} = await this.$api.post(`/broadcasts/:instance/draft`,this.emailObj.broadcast)
					this.emailObj.broadcast = br
					this.$message.success('Succesfully Saved')
					this.$store.commit('SET_PROP', {where:['broadcasts',br.id],what:br})
					this.$router.replace(`/draft/${br.id}?edit`)
				}
			})
			.catch(err => {
				this.$message.error('A problem happened. Please try and save again.')
			})
			.finally( () => {
				this.$store.commit('LOAD',false)
			})


		}

	},
	save(cb){


		this.$store.commit('LOAD',true)
		this.pkgProject = this.packageProject()

		// document.querySelector("iframe").contentWindow.postMessage({
		// 	action: "screenshot",
		// 	for: 'save',
		// });

		this.saveCb = cb
		this.continueSaving('')



	},
	async saveTemplate(){
		this.$store.commit('LOAD',true)
		this.pkgProject = this.packageTemplateProject()
		document.querySelector("iframe").contentWindow.postMessage({
			action: "screenshot",
			for: 'save',
		});

	},
	async updateFrame() {

		if (!document.querySelector('iframe.email-builder')) return

		this.$store.commit('LOAD', true)

		scrollTop = document.querySelector('iframe.email-builder').contentDocument.body.scrollTop
		this.emailObj.scrollTop = scrollTop
		let { data } = await axios.post(`${this.siteURL}/email`, this.emailObj)
		//   this.latestHTML = data
		// let data = await renderOut(this.emailObj)
		var doc = document.querySelector('iframe.email-builder')?.contentWindow?.document;
		if (doc) {
			doc.open();
			doc.write(data);
			doc.close();
		}

		setTimeout(() => {
			document.querySelector('iframe.email-builder').contentDocument.body.scrollTo(0, scrollTop)
			this.$store.commit('LOAD', false)
		}, 0);
	},
	topbarAction(action){
	if (action == 'preview'){
		console.log('PREVIEW CLICKED')
	} else if (action == 'edit'){
		console.log('EDIT CLICKED')
	} else if (action == 'code'){
		console.log('CODE CLICKED')
	}
	},
	nextStep(){
		console.log('NEXT STEPPPP')
		this.continue()

	},
	async downloadPdf() {
		console.log('Downloading PDF...');
		this.$store.commit('LOAD', true);

		try {
			const { data: html } = await axios.post(
				`${this.siteURL}/email?test=1&fname=John&lname=Doe&email=john@doe.com`,
				this.emailObj
			);

			if (html) {
				let self = this;
				let pdf = new jsPDF();

				pdf.html(html, {
					callback: function (doc) {
						console.log('DOC', doc);
						self.$store.commit('LOAD', false);
						self.$message.success('Successfully Downloaded PDF');
						doc.save(`${self.emailObj.SUBJECT}.pdf`);
					},
					margin: [10, 10, 10, 10],
					orientation: 'portrait', // or 'landscape'
					x: 0,
					y: 0,
					width: 165,
					windowWidth: 900
				});
			}
		} catch (error) {
			console.error('Error:', error);
			this.$store.commit('LOAD', false);
			this.$message.error('Failed to generate PDF');
		}
	},
	async exportHtml() {
		console.log('Exporting HTML...');
		this.$store.commit('LOAD', true);

		try {
			const { data: html } = await axios.post(
				`${this.siteURL}/email?test=1&fname=John&lname=Doe&email=john@doe.com`,
				this.emailObj
			);

			if (html) {
				const blob = new Blob([html], { type: 'text/html;charset=utf-8' });

				const downloadLink = document.createElement('a');
				downloadLink.href = URL.createObjectURL(blob);

				downloadLink.download = `${this.emailObj.SUBJECT}.html`;

				downloadLink.click();
				this.$store.commit('LOAD', false);
			}
		} catch (error) {
			console.error('Error:', error);
			this.$store.commit('LOAD', false);
			this.$message.error('Failed to export HTML');
		}
	},
	async sendTest(e){
		console.log('SENDING EMAILSSSSSSS', e)
			this.$message.loading({ content: 'Sending Test...', key:'sendTest' });

		let {data:html} = await axios.post(`${this.siteURL}/email?test=1&fname=John&lname=Doe&email=john@doe.com`, this.emailObj)

		this.$api.post(`/services/email/send`,{
			to:e,
			subject:`[TEST] - ${this.emailObj.SUBJECT} ${Date.now()}`,
			from:'promotion@test.bildhive.net',
			html
		}).then( ({data}) => {
			this.$message.success({ content: 'Test sent!', key:'sendTest', duration: 2 });
		}).catch( () => {
			this.$message.error({ content: 'There was a problem sending test. Please try again.', key:'sendTest', duration: 2 });
		})
	},
	closeAddContent(){
		this.addContent.show = false
	},
	resetColor(){
		this.colorPicker = {
		  show:false,
		  path:[],
		  callback:null
		}
	},
	colorCallBack(color){
		setProp(this,this.colorPicker.path,color.hex)
		this.resetColor()
	},
	chooseColor(path){
		this.colorPicker = {
		  show:true,
		  path,
		  callback:this.colorCallBack
		}
	},
	updateMenu(menus){
		this.navMenus = menus
	},
	async saveRecentColors(clrs){
		let {data} = await this.$api.put(`/websites/${this.instance.id}/${this.site.id}`,{cson}).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
		if (!data.id) return this.$message.error('Something went wrong. Please Try Again.')
	},
	  reset(){
		this.frameSize = {current:'desktop'}
	  },
	  close(){
		this.reset()
		this.$store.commit('SET_PROP', {where:'templateDrawer',what:{
		  show:false,
		  type:'add',
		  site:'',
		  data:{}
		}})
	  },
		closeTemplate(){
			this.reset()
			this.$store.commit('SET_PROP', {where:'templateDrawer',what:{
				show:false,
				type:'add',
				site:'',
				data:{}
			}})

			if (this.$route.params && this.$route.params.id){
				this.$router.push(`/`)
				this.$store.commit('EMAIL_TYPE', {value:'Templates'})
			}
		},
		continueStep(){
			this.$emit('next',this.emailObj)
			this.$store.commit('CURRENT_STEP', Date.now())
			this.$store.commit('CLOSE_DRAWERS')
		},
		saveAndContinue() {
			if (!this.senders.active){
				console.log('SENDER NOT ACTIVE', this.senders)
				return this.$message.error('Please verify your email account in settings before proceeding.',5)
			}
			if (!this.checkUnsubscribeLink()){
				console.log('NO UNSUBSCRIBE LINK')
				return this.$message.error('Error: No unsubscribe link found. Please replace your footer widget with a new one that includes the unsubscribe link before proceeding.', 6);
			}
			if (this.emailObj.id){
				this.save(this.continueStep)
			} else {
				this.continueStep()
			}

		},
	},
	created(){



	  window.onmessage = (e) => {

		if (e && e.data && e.data.source && typeof e.data.source === 'string' && e.data.source.includes('vue')) return  null
		if (typeof e.data.type === 'string' && e.data.type.includes('webpack')) return null
		if (!e.data.action) return

		console.log('LISTENIN FROM PARENT', e)
		console.log(`Received message:`, e.data);

		let evt = e.data
		this.windowMsg = evt
		if (evt.action === 'onload'){
		  setTimeout(() => {
			if (this.$store.state.email.editSectionObj.show && this.$store.state.email.editSectionObj.item && this.$store.state.email.editSectionObj.item.id){
			  document.querySelector('iframe').contentWindow.postMessage({action:'selectedSection',id:this.$store.state.email.editSectionObj.item.id})
			}
		  }, 0);

		} else if (evt.action.includes('section')){

		  if (evt.action === 'sectionEdit' && evt.id){
			this.selectedSection = evt.id
		  }

		}
		else if (evt.action === 'appendSection' && evt.id){
		  this.prevSection = evt.id
		} else if (evt.action === "screencaptured" && evt.for) {

			console.log('SCREEN CAPTURED', evt)
			if (this.$route.path.includes('template')){
				this.continueSavingTemplate(evt.value)
			} else {
				// this.continueSaving(evt.value)
			}

		}

	  }

	},
	mounted(){
	  if (this.emailObj){
		if (!this.emailObj.data) this.emailObj.data = {}
	  }
	}
  };
</script>
<style lang="scss" scoped>
  .field-box{
	border:1px solid var(--light-gray);
  }
  [class*=field-box-]{
	position:absolute; top:10px; right:10px; cursor: pointer;
	transition: color .3s ease-out;
	&:hover{
	  color:var(--light-purple);
	}
  }
  .field-box-edit{
	right:30px;
	&:hover{
	  color:var(--orange);
	}
  }
  .preview-form .ant-card-head{
	background:var(--off-white-dark);
  }
  .builder-screen{
	opacity:0;
	position:fixed;
	top:0;
	left:0;
	width:100vw;
	height:100vh;
	z-index:100;
	transform:translate3d(0,50px,0);
	animation:builder-in .3s ease-out;
	animation-fill-mode: forwards;
  }
  @keyframes builder-in{
	to{
	  opacity:1;
	  transform:translate3d(0,0,0);
	}
  }

</style>


<style lang="scss">
  .widget-settings{
	[role=tab]{
	  padding-top:0;
	}
  }
  .email-builder-wrapper{
	background:#efefef;
	overflow:hidden;
  }
  .email-builder{
	box-shadow:1px 3px 5px rgba(100,100,100,0.3);
	width:100%;
	height:100%;
  }
  .phone {
	position: absolute;
	top: 120px;
	> iframe {
	  position: relative;
	  width: 269px;
	  height: 480px;
	  top: 50px;
	  left: 14px;
	  background: #fff;
	  z-index: 999;
	}
  }
  .iframe-holder{
	position: absolute;
	top:50%;
	left:50%;
	transform:translate3d(-50%,-50%,0);
	width:100%;
	height:100%;
  }

  .phone-iframe-container {
	width: 300px;
	height: 594px;
	background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/121761/Nexus_5_Mockup_300px.png');
	background-position: center center;
	background-repeat: no-repeat;
	z-index: 999;
	position: absolute;
  }

</style>
