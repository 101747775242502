<template >
    <div class="bg-white">
        <a-form class="section-editor-container bg-white py-4 px-3">
            <a-form-item label="Email Background">
                <ColorPicker v-model="email.data.bg" />
            </a-form-item>
            <a-form-item label="Border around Design" class="mb-1" v-if="email.data.hasOwnProperty('_border')">
                <a-switch v-model="email.data._border" />
            </a-form-item>
            <template v-if="email.data._border">
                <a-form-item label="Border Width" class="mb-1">
                    <a-select :options="borderWidthOptions" v-model="email.data._borderWidth" />
                </a-form-item>
                <a-form-item label="Border Color" class="mb-0">
                <ColorPicker v-model="email.data._borderColor" />
                </a-form-item>
            </template>
        </a-form>
        <a-divider />
        <h4 class="pl-3">Global Colors</h4>
        <a-form class="section-editor-container py-4 px-3">
            <a-form-item label="Primary Color">
               <ColorPicker v-model="email.data.primaryColor" />
            </a-form-item>
            <a-form-item label="Secondary Color">
                <ColorPicker v-model="email.data.secondaryColor" />
            </a-form-item>
            <a-form-item label="Accent Color">
                <ColorPicker v-model="email.data.accentColor" />
            </a-form-item>

        </a-form>

    </div>
</template>

<script>
import ColorPicker from '@/components/email/ColorPicker'
export default {
    props:['email'],
    components:{ColorPicker},
    data:() => ({

    }),
    computed:{
        borderWidthOptions(){
            let widths = []
            for(let i = 0; i < 4; i++){
                widths.push({label:i+1,value:(i+1)+'px'})
            }
            return widths
        },
        border:{
            get(){
                return Boolean(this.email.data._border)
            },
            set(val){
                console.log('SET VAL', val)
                this.email.data._border = val
            }
        },
        fonts(){
            return this.$store.getters.fonts
        },
        list(){
            return this.fonts.map(x => ({
                label:x.name,
                value:x.name
            }))
        },
        primary:{
            set(val){
                let font = this.fonts.find(x => x.name === val)
                if (!font) return

                this.email.fonts.primary = JSON.parse(JSON.stringify(font))
            },
            get(){
                return this.email.fonts.primary.name
            }
        },
        secondary:{
            set(val){
                let font = this.fonts.find(x => x.name === val)
                if (!font) return

                this.email.fonts.secondary = JSON.parse(JSON.stringify(font))
            },
            get(){
                return this.email.fonts.secondary.name
            }
        }
    },
    methods:{


    },

}
</script>

<style lang="scss">
   .bt-next{
       border-top:1px solid var(--light-purple);
       border-bottom:1px solid var(--light-purple);
       & + .bt-next{
           border-top:0;
       }

   }
</style>
