<template>
    <div>
        <div class="dF aC px-3 py-4" style="gap:1ch;" >
            <a-icon type="arrow-left" class="cursor-pointer" v-if="setting" @click="setting = ''" />
            <h6 class="mb-0" style="font-size: 1.15em;">{{setting ? emailSettings[setting] : 'Email Settings'}}</h6>
        </div>
        <template v-if="!setting">
            <BuilderSettingCard :item="item" v-for="(item,itemKey) in emailSettings" :key="itemKey" @click.native="edit(itemKey)" />
        </template>
        <template v-else>
            <FontSettings :email="email" v-if="setting === 'fonts'" />
            <AppearanceSettings :email="email" v-if="setting === 'appearance'" />
        </template>
    </div>
</template>

<script>
import BuilderSettingCard from '@/components/email/BuilderSettingCard'
import FontSettings from '../email/FontSettings'
import AppearanceSettings from '../email/AppearanceSettings'

export default {
    props:['email'],
    components:{
        BuilderSettingCard,
        FontSettings,
        AppearanceSettings,

    },
    data:()=>({
        setting:'',
        emailSettings:{
            fonts:'Fonts',
            appearance:'Design Settings'
        },
        
    }),
    methods:{
        edit(val){
            this.setting = val
        }
    }
}
</script>

<style>

</style>